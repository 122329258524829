type Langs = Map<string, Map<string, string>>;

function importToLang(im: Record<string, string>) {
  let data: Langs = new Map();
  for (const pathy in im) {
    const removeLastName = pathy.slice(undefined, -".lang".length);
    const filePathList = removeLastName.split("/");
    const langName = filePathList[filePathList.length - 1];
    const jsonObj = JSON.parse(im[pathy]);
    data.set(langName, new Map(Object.entries(jsonObj)));
  }
  return data;
}

const commonLangString = import.meta.glob("../../system/language/*.lang", {
  as: "raw",
  eager: true,
});
const commonLang = importToLang(commonLangString);

export { importToLang, commonLang };
export type { Langs };
