import { Link } from "react-router-dom";

export default function Video2Edit() {
  return (
    <div>
      <Link to="..">Back</Link>
      <p>
        Lorem ipsum dolor sit, amet consectetur adipisicing elit. Cupiditate,
        officiis.
      </p>
    </div>
  );
}
