import React from "react";
import ReactDOM from "react-dom/client";

import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, toast, Bounce } from "react-toastify";

function initializeToasts() {
  ReactDOM.createRoot(
    document.getElementById("toast-container") as HTMLElement
  ).render(
    <React.StrictMode>
      <ToastContainer
        position="bottom-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={true}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
        transition={Bounce}
      />
    </React.StrictMode>
  );
}
export default initializeToasts;
export { toast };
