import { module } from "@core/urlparser";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import type { RouteObject } from "react-router-dom";
import Video2Routes from "./Video2/scripts/routes";
import Video2Layout from "./Video2/scripts/video2-layout";

const routes: RouteObject[] = [
  {
    path: "video2",
    element: <Video2Layout />,
    children: Video2Routes,
  },
];

function App() {
  const router = createBrowserRouter(routes);
  return <RouterProvider router={router}></RouterProvider>;
}

// Checks if current base route is in router
function shouldRender() {
  for (const route of routes) {
    if (route.path === module) {
      return true;
    }
  }
  return false;
}

export { shouldRender, App };
