import { shouldRender, App } from "@mod/moduleimporter.tsx";
import React from "react";
import ReactDOM from "react-dom/client";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

function renderTimini() {
  if (shouldRender()) {
    const qClient = new QueryClient();

    ReactDOM.createRoot(document.getElementById("csr") as HTMLElement).render(
      <React.StrictMode>
        <QueryClientProvider client={qClient}>
          <App />
        </QueryClientProvider>
      </React.StrictMode>
    );
  }
}

export default renderTimini;
