import { toast } from "@core/banto_ui";
import "../styles/video2.css";
import { useQuery } from "@tanstack/react-query";
import { commonDataCacheKey, getCommonData } from "@core/common_data";
import { importToLang } from "@core/lang";

const langStrings = import.meta.glob("../language/*.lang", {
  as: "raw",
  eager: true,
});
const glossary = importToLang(langStrings);

interface Video2Data {
  id: string;
  description: string;
}

async function getVideoData(): Promise<Video2Data> {
  const res = await fetch("/video2/init");
  return await res.json();
}

export default function Video2() {
  const videoQuery = useQuery({
    queryKey: ["video2"],
    queryFn: getVideoData,
  });

  // NB: Alle queries som bruker samme key blir cachet
  const langQuery = useQuery({
    queryKey: [commonDataCacheKey],
    queryFn: getCommonData,
  });

  function testOldToast() {
    // @ts-ignore
    banto.ui.showError("Error from old");
  }

  if (videoQuery.isLoading || langQuery.isLoading) {
    // Goofy ahh layout shift footgun
    // Vi må finne på noe lurt i hver eneste modul
    return <p>"LOADING"</p>;
  }

  if (!videoQuery.isSuccess || !langQuery.isSuccess) {
    console.error("fetch error");
    console.error(videoQuery.error);
    console.error(langQuery.error);
    return <p>"ERROR"</p>;
  }

  const ln = glossary.get(langQuery.data.preferredLanguage);
  if (!ln) {
    console.error("lang error");
    return <p>ERROR</p>;
  }

  return (
    <>
      <div>
        Hei {langQuery.data.currentUsername}, du er i {ln.get("MODULE_NAME")}
      </div>
      <button onClick={() => toast.error("Error")}>New error</button>
      <button onClick={testOldToast}>Old error</button>
      <button onClick={() => toast.success("Some success")}>New success</button>
      <button onClick={() => toast.info("Some info")}>New info</button>
      <button onClick={() => toast.warning("Some warning")}>New warning</button>
      <br />
      <iframe
        src={`https://www.youtube-nocookie.com/embed/${videoQuery.data.id}?rel=0`}
        width="560"
        height="315"
        allowFullScreen
      ></iframe>
      <div className="video2-description">{videoQuery.data.description}</div>
    </>
  );
}
