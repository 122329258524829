interface CommonBantoData {
  loggedIn: boolean;
  currentUsername: string;
  currentUserID: number;
  preferredTheme: string;
  preferredLanguage: string;
  preferredCursor: string;
}

const commonDataCacheKey = "common_data_csr";

async function getCommonData() {
  const res = await fetch("/client_side_rendering");
  const data = (await res.json()) as CommonBantoData;
  return data;
}

export { getCommonData, commonDataCacheKey };
export type { CommonBantoData };
