import Video2 from "./video2";
import Video2Edit from "./video2-edit";
import type { RouteObject } from "react-router-dom";
import { lazy, Suspense } from "react";

const LargeComponent = lazy(() => {
  console.log("running lazy");
  return import("./video2-largeroute");
});

const Video2Routes: RouteObject[] = [
  {
    element: <Video2></Video2>,
    path: "",
  },
  {
    element: <Video2Edit></Video2Edit>,
    path: "edit",
  },
  {
    element: (
      <Suspense fallback={<div>Loading</div>}>
        <LargeComponent />
      </Suspense>
    ),
    path: "largeroute",
  },
];

export default Video2Routes;
