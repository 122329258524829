import { Outlet, Link } from "react-router-dom";

function Video2Layout() {
  return (
    <div className="container">
      <div>
        <p>
          Man kan ha layout-kode på ruter. Da må man bruke Outlet-komponenten.
        </p>
        <Link className="button" to=".">
          Oversikt
        </Link>
        <Link className="button" to="./edit">
          Rediger
        </Link>
        <Link className="button" to="./largeroute">
          Denne store komponenten lastes async
        </Link>
      </div>
      <Outlet />
    </div>
  );
}

export default Video2Layout;
